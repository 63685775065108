<template>
    <SmartForm
        ref="protocolform"
        base_url="/protocol"
        base_path="/protocol"
        form_type="new"
    ></SmartForm>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import SmartForm from "@/view/components/SmartForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartForm,
     },
     mounted() {
         this.$watch(
             () => {
                 let fields = this.$refs.protocolform.fields;

                 if (fields.length)
                 {
                     if (this.$refs.protocolform.form_type === 'edit' || this.$refs.protocolform.form_type === 'copy')
                     {
                         if (!this.$refs.protocolform.values_loaded) return false;
                     }

                     let field = this.findField(this.$refs.protocolform, 'idsportingclub');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;
                 }

                 if (doUpdate) {
                     let field = this.findField(this.$refs.protocolform, 'idannuality');
                     let annuality = this.getFieldValue(field);

                     let idannuality = 0;
                     if (annuality && annuality.id) idannuality = annuality.id;

                     let url = '/protocol/get-sportingclub-data?idannuality=' + idannuality + '&idsportingclub=' + newValue;

                     let vm = this;
                     ApiService.query(url)
                               .then((response) => {
                                   const values = response.data;

                                   vm.setFieldAttribute(vm.$refs.protocolform, 'sportingclub', 'value', values.sportingclub);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'taxcode', 'value', values.taxcode);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'city', 'value', values.city);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'streetaddress', 'value', values.streetaddress);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'streetnumber', 'value', values.streetnumber);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'zipcode', 'value', values.zipcode);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardsurname', 'value', values.boardsurname);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardname', 'value', values.boardname);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardtaxcode', 'value', values.boardtaxcode);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardbirthplace', 'value', values.boardbirthplace);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardbirthdate', 'value', values.boardbirthdate);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'boardaddress', 'value', values.boardaddress);
                                   vm.setFieldAttribute(vm.$refs.protocolform, 'committee', 'value', values.committee);
                               })
                               .catch((error) => {
                                   console.log(error);
                               })
                 }
             }
         );

         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Comitati", route: "/committee/index" },
             { title: "Certificati Attestazione APS", route: "/protocol/index" },
             { title: "Nuovo Certificato Attestazione APS" }
         ]);
     },
     methods: {
         findField(obj, name) {
             if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                 return obj.fields.find(item => item.name === name);
             }

             return null;
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(obj, name, visible) {
             if (typeof obj.fields !== "undefined") {
                 let fieldIndex = obj.fields.findIndex(item => item.name === name);
                 if (fieldIndex) {
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                 }
             }
         },

         setFieldAttribute(obj, name, attribute, value) {
             let fieldIndex = obj.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(obj.fields[fieldIndex], attribute, value);
             }
         },
     },
 };
</script>
